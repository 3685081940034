<template>
  <div v-if="isAdmin || checkAccess('sites-statement')">
    <report-list-page
        page-title="Statement of not selling sites"
        page-icon="mdi-sale"
        sort="site_id"
        :descending="true"
        :headers="headers"
        state-end-point="sitesStatement.statementsOfNotSellingSites"
        :actions="actions"
        :show-component="showComponent"
        item-key="id"
    />
  </div>
</template>

<script>
import ReportListPage from "../../components/ReportListPage";
import IsAuthenticated from "../../middleware/IsAuthenticated";
export default {
  name: "StatementReport",
  mixins: [IsAuthenticated],
  components: {ReportListPage},
  data() {
    return {
      headers: [
        {
          text: 'Site ID',
          value: 'site_id'
        },
        {
          text: 'Site URL',
          value: 'site_url'
        },
        {
          text: 'Price',
          align: 'right',
          value: 'price'
        },
        {
          text: 'Reseller Price',
          align: 'right',
          value: 'reseller_price'
        },
        {
          text: 'Link Price',
          align: 'right',
          value: 'linkPrice'
        },
        {
          text: 'Adult Price',
          align: 'right',
          value: 'adultPrice'
        }
      ],
      actions: {
        load: 'loadSitesStatement',
        downloadPDF: 'downloadSitesStatementAsPDF'
      },
      showComponent: {
        isUser: false,
        isStatus: false,
        isMethod: false,
        isEmail: false,
        isSite: false,
        isPDF: false,
        isDate: false
      }
    }
  }
}
</script>

<style scoped>

</style>